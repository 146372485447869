import './App.css';

import React from 'react';

import UnderConstruction from './screens/UnderContruction';

function App() {
  return <UnderConstruction />;
}

export default App;

import './Optin.css';

import React from 'react';

function Optin() {
  return (
    <div className="optin">
      <h3>Our site is getting a little tune up and some love.</h3>
      <p>
        We apologize for the inconvenience, but we&apos;re performing some maintenance. You can
        still contact us at{' '}
        <a href="mailto:contact@vijaybhalothia.com">contact@vijaybhalothia.com</a>. We&apos;ll be
        back up soon!
      </p>
    </div>
  );
}

export default Optin;

import React from 'react';

import Timer from './components/Countdown/Timer';
import Optin from './components/Optin/Optin';

function UnderConstruction() {
  return (
    <div className="App">
      <div className="container">
        <h1>
          Website
          <br />
          Under Maintenance
        </h1>
        <Timer deadline={new Date().getTime()} />
        <Optin />
      </div>
    </div>
  );
}

export default UnderConstruction;

import './Timer.css';

import React, { useEffect, useRef, useState } from 'react';

type IProps = { deadline: number };

type IState = {
  days: string | number;
  minutes: string | number;
  hours: string | number;
  seconds: string | number;
  time_up?: string;
};

function CountDown(props: IProps) {
  const { deadline } = props;

  const timer = useRef<NodeJS.Timer>();

  const [state, setState] = useState<IState>({
    days: 0,
    minutes: 0,
    hours: 0,
    seconds: 0,
    time_up: '',
  });
  const { days, seconds, hours, minutes } = state;

  useEffect(() => {
    timer.current = setInterval(count, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const count = () => {
    const now = new Date().getTime();
    const t = deadline - now;
    const dd = Math.floor(t / (1000 * 60 * 60 * 24));
    const hh = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const mm = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
    const ss = Math.floor((t % (1000 * 60)) / 1000);

    const days = dd < 10 ? '0' + dd : dd;
    const hours = hh < 10 ? '0' + hh : hh;
    const minutes = mm < 10 ? '0' + mm : mm;
    const seconds = ss < 10 ? '0' + ss : ss;

    setState({ days, minutes, hours, seconds });

    if (t < 0) {
      clearInterval(timer.current as NodeJS.Timeout);
      setState({
        days: 0,
        minutes: 0,
        hours: 0,
        seconds: 0,
        time_up: 'TIME IS UP',
      });
    }
  };

  return (
    <div id="countdown">
      <div className="col-4">
        <div className="box">
          <p id="day">{days}</p>
          <span className="text">Days</span>
        </div>
      </div>
      <div className="col-4">
        <div className="box">
          <p id="hour">{hours}</p>
          <span className="text">Hours</span>
        </div>
      </div>
      <div className="col-4">
        <div className="box">
          <p id="minute">{minutes}</p>
          <span className="text">Minutes</span>
        </div>
      </div>
      <div className="col-4">
        <div className="box">
          <p id="second">{seconds}</p>
          <span className="text">Seconds</span>
        </div>
      </div>
    </div>
  );
}

export default CountDown;
